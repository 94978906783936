// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAdOhQGskdWbuJUyPRmTvsgXVhaykGkQrE",
  authDomain: "lawbahn-176d9.firebaseapp.com",
  projectId: "lawbahn-176d9",
  storageBucket: "lawbahn-176d9.appspot.com",
  messagingSenderId: "386003664337",
  appId: "1:386003664337:web:e29d687279fdfaaed3f4f0",
  measurementId: "G-5VVXM7LJXS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);


// Initialize Firebase Authentication and get a reference to the service
export {db}
