<template>
  <div class="body">
    <header>
          <div class="header-content">
            <img src="../assets/로고.png" style="width: 135px;">
          </div>
        </header>
    
        <main>
          <div style="height: 140px;"></div>
          <h2>LawPlus 상담 받아보기</h2>
          <div style="height: 25px;"></div>
          <p>1분만에, 손쉽게 상담을 신청해보세요.</p>
          <div style="height: 46px;"></div>

          <div class="input-wrapper">
            <div class="select">
              <label for="dropdown" class="input-title">상담 종류 <span style="color: #FF6400;"> *</span></label>
              <select name="dropdown" id="dropdown" v-model="caseType">
                <option disabled selected></option>
                <option value="normal">일반 법률상담</option>
                <option value="creditRecovery">개인회생 상담</option>
              </select>
            </div>
          </div>
          <div style="height: 15px;"></div>
          <div class="input-wrapper">
            <input class="short-text" type="text" placeholder="입력해주세요" id="inputField" v-model="name">
            <label for="inputField" class="input-title">성함 <span style="color: #FF6400;"> *</span></label>
          </div>
          <div style="height: 15px;"></div>
          <div class="input-wrapper">
            <input class="short-text" type="text" placeholder="입력해주세요" id="inputField" v-model="phoneNum">
            <label for="inputField" class="input-title">전화번호 <span style="color: #FF6400;"> *</span></label>
          </div>
          <div style="height: 15px;"></div>
          <div class="input-wrapper">
              <input class="short-text" type="text" placeholder="입력해주세요" id="inputField" v-model="age">
              <label for="inputField" class="input-title">나이 <span style="color: #FF6400;"> *</span></label>
          </div>
          <div style="height: 15px;"></div>
          <div class="input-wrapper">
              <input class="short-text" type="text" placeholder="입력해주세요" id="inputField" v-model="address">
              <label for="inputField" class="input-title">주소(시/군/구까지) <span style="color: #FF6400;"> *</span></label>
          </div>
          <div style="height: 15px;"></div>
          <div class="input-wrapper">
              <input class="short-text" type="text" placeholder="입력해주세요" id="inputField" v-model="jobNow">
              <label for="inputField" class="input-title">현재 직업 <span style="color: #FF6400;"> *</span></label>
          </div>
          <div style="height: 15px;"></div>
          
          <!-- 아래는 일반 법률상담 시 넣을 것들 -->
          <div class="input-wrapper" v-if="caseType=='normal'">
            <div class="textarea">
              <textarea placeholder="입력해주세요" id="inputField" style="height: 120px;" v-model="message"></textarea>
            <label for="inputField" class="input-title">상담할 내용(100자 이상) <span style="color: #FF6400;"> *</span></label>
            </div>
          </div>
          
          <!-- 쿠폰 -->
          <div style="height: 15px;" v-if="caseType=='normal'"></div>
          <div class="input-wrapper" v-if="caseType=='normal' && couponCertified==false">
              <input class="short-text" type="text" placeholder="입력해주세요" id="inputField" v-model="userInputCoupon">
              <label for="inputField" class="input-title">쿠폰 입력(선택)</label>
          </div>
          <p v-if="caseType=='normal' && couponCertified==true">쿠폰 인증 완료</p>
          <button v-on:click="checkCouponString" v-if="caseType=='normal' && couponCertified==false">쿠폰 인증하기</button>
          
          <!-- 여기까지 일반 법률상담 -->

          <!-- 아래는 개인회생 상담 시 넣을 것들 -->
          <div v-if="caseType=='creditRecovery'">
            <div class="input-wrapper">
              <input class="short-text" type="text" placeholder="입력해주세요" id="inputField" v-model="debt">
              <label for="inputField" class="input-title">부채 규모 <span style="color: #FF6400;"> *</span></label>
            </div>
            <div style="height: 15px;"></div>
            <div class="input-wrapper">
              <div class="select">
                <label for="dropdown" class="input-title">신용회복 후 희망직업 <span style="color: #FF6400;"> *</span></label>
                <select name="dropdown" id="dropdown" v-model="jobFuture" >
                  <option disabled selected>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</option>
                  <option value="급여 일자리">급여 일자리</option>
                  <option value="자영업 창업">자영업 창업</option>
                  <option value="벤처 창업">벤처 창업</option>
                </select>
              </div>
            </div>
            <div style="height: 15px;"></div>
            <div class="input-wrapper">
              <div class="textarea">
                <textarea placeholder="입력해주세요" id="inputField" style="height: 120px;" v-model="message"></textarea>
                <label for="inputField" class="input-title">변호사에게 전할 말 <span style="color: #FF6400;"> *</span></label>
              </div>
            </div>
          </div>
          
          <!-- 여기까지 개인회생 상담 -->



          <div style="height: 15px;"></div>
          <div class="checkbox-container">
            <p v-on:click="goToInfoPolicyDoc">개인정보 처리방침에 동의합니다.</p>
            <input type="checkbox" id="checkbox" v-model="agreeInfoPolicy" />
           
        </div>
        <div class="checkbox-container">
          <p v-on:click="goToServicePolicyDoc">서비스 이용약관에 동의합니다.</p>
            <input type="checkbox" id="checkbox" v-model="agreeServicePolicy" />
        </div>
        <div style="height: 30px;"></div>


          
          <button v-on:click="submitNormalCase" v-if="caseType=='normal'">상담 신청하기</button>
          <button v-on:click="submitCreditRecoveryCase" v-if="caseType=='creditRecovery'">회생상담 신청하기</button>
          <div style="height: 55px;"></div>


          </main>
    
        <footer>
            <div style="height: 50px;"></div>
            <p><span style="color: #FFFFFF;">
                대표이사   |   김민석<br>
                로반 주식회사   |    729-86-01007<br>
                주소   |   서울특별시 서초구 강남대로 107길 21, 2층<br>
                대표번호   |   010-8081-2132<br>
                E-mail   |   tellmedia@naver.com
            </span></p>
            <div style="height: 50px;"></div>
        </footer>
   
   </div>



  </template>

<script>
/* eslint-disable */
import { db } from '../firebase/index.js'
import {collection, addDoc, Timestamp, getDoc, doc} from 'firebase/firestore'

export default {
  name: 'Register-view',
  data() {
    return {
      // caseType : normal or creditRecovery
      caseType: '',
      name:'',
      phoneNum:'',
      age:'',
      address:'',
      jobNow:'',
      debt:'',
      jobFuture:'',
      message:'',
      couponCertified: false,
      realCoupon:'',
      userInputCoupon:'',
      // couponChecked: false,
      agreeInfoPolicy: false,
      agreeServicePolicy: false,
      sthMissed : !this.sthMissedCheck
    }
  },
  methods: {
    isEmpty (value){
      if( value == "" || value == null || value == undefined ){
        return true
      }else{
        return false
      }
    },
    sthMissedCheck () {
        return this.isEmpty(this.name)
    },
    goToInfoPolicyDoc () {
      window.open("https://docs.google.com/document/d/1HL9EBbNueundnbMbxSClIGPmTSa9N5ASJTRrehRcSDg/edit?usp=sharing",
       "_blank")
    },
    goToServicePolicyDoc () {
      window.open("https://docs.google.com/document/d/1CYygVXLa3QkTJFSOIJEMJPlsvPJABZpZTxq-J730MgQ/edit?usp=sharing",
       "_blank")
    },
    checkAgreeInfoPolicy () {
      !this.agreeInfoPolicy
    },
    checkAgreeServicePolicy () {
      !this.agreeInfoPolicy
    },
		async submitCreditRecoveryCase() {
      if(this.agreeInfoPolicy && this.agreeServicePolicy) {
        if(!this.isEmpty(this.name) 
          && !this.isEmpty(this.phoneNum)
          && !this.isEmpty(this.age) 
          && !this.isEmpty(this.address) 
          && !this.isEmpty(this.jobNow)
          && !this.isEmpty(this.debt) 
          && !this.isEmpty(this.jobFuture)
          && !this.isEmpty(this.message)) {
          const colRef = collection(db, 'cases')
          const currentDate = Timestamp.now()
          const dataObj = {
            name: this.name,
            phoneNum: this.phoneNum,
            age: this.age,
            address: this.address,
            jobNow: this.jobNow,
            debt: this.debt,
            jobFuture: this.jobFuture,
            message: this.message,
            isAccepted: false,
            createdAt: currentDate,
            caseType: 'creditRecovery',
            couponCertified: false,
          }

          await addDoc(colRef, dataObj).then(
            () => {
              this.$router.push("/case-list")
            }
          )
        } else {
          alert(`모든 정보를 빠짐없이 기입해주세요.`)
        }
      } else {
        alert(`개인정보처리방침과 서비스이용약관에 모두 동의해주세요.`)
      }
      
    },
    async submitNormalCase() {
        if(this.agreeInfoPolicy && this.agreeServicePolicy) {
          if(!this.isEmpty(this.name) 
            && !this.isEmpty(this.phoneNum)
            && !this.isEmpty(this.age) 
            && !this.isEmpty(this.address) 
            && !this.isEmpty(this.jobNow)
            && !this.isEmpty(this.message)) {
              if(this.message.length < 100) {
                alert(`상담 내용은 100자 이상 작성해주세요.`)
              } else {
                const colRef = collection(db, 'cases')
                const currentDate = Timestamp.now()
                const dataObj = {
                  name: this.name,
                  phoneNum: this.phoneNum,
                  age: this.age,
                  address: this.address,
                  jobNow: this.jobNow,
                  message: this.message,
                  isAccepted: false,
                  createdAt: currentDate,
                  caseType: 'normal',
                  couponCertified: this.couponCertified,
                }

                await addDoc(colRef, dataObj).then(
                  () => {
                    this.$router.push("/case-list")
                  }
                )

              }
          } else {
            alert(`모든 정보를 빠짐없이 기입해주세요.`)
          }
        } else {
          alert(`개인정보처리방침과 서비스이용약관에 모두 동의해주세요.`)
        }
        
    },
    async checkCouponString() {
      const docRef = doc(db, "coupon", "coupon1")
      const docSnap = await getDoc(docRef)

      if(docSnap.exists()) {
        this.realCoupon = docSnap.data().coupon1String
        if (this.userInputCoupon == this.realCoupon) {
          this.couponCertified = true
          alert('쿠폰이 정상적으로 인증되었습니다.')
        } else {
          console.log('wrong coupon input')
          alert('인증에 실패했습니다. 쿠폰 내용을 재확인해주세요.')
        }
      } else {
        console.log('not found')
        alert('네트워크 오류가 발생했습니다. 다시 시도해주세요.')
      }
    },
  },
  // created() {
  //   this.createUser()
  // },
  components: {
    
  }
}
</script>

  