<template>
<div class="body">
        <header>
          <div class="header-content">
            <img src="../assets/로고.png" style="width: 135px;">
          </div>
        </header>
    
        <div class="main">
          <div style="height: 200px;"></div>
          <h1>고객님의 새 출발,<br>이제 LawPlus와 함께.</h1>
          <div style="height: 25px;"></div>
          <!-- <p>변호사 n명이 고객님을 기다리고 있습니다.</p> -->
          <div style="height: 40px;"></div>
          <button v-on:click="goToRegisterView">1분만에 무료상담 신청하기</button>
        </div>
    
        <footer style="margin-top: -10px;">
            <div style="height: 50px;"></div>
            <p><span style="color: #FFFFFF;">
                대표이사   |   김민석<br>
                로반 주식회사   |    729-86-01007<br>
                주소   |   서울특별시 서초구 강남대로 107길 21, 2층<br>
                대표번호   |   010-8081-2132<br>
                E-mail   |   tellmedia@naver.com
            </span></p>
            <div style="height: 50px;"></div>
        </footer>
    </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {
    
  },
  methods: {
     goToRegisterView () {
      this.$router.push('/register')
     },
   }

}
</script>
