<template>
  <div class="body">
    <header>
          <div class="header-content">
            <img src="../assets/로고.png" style="width: 135px;">
          </div>
        </header>
    
        <main>
          <!-- 신청완료 팝업 -->
          <div style="height: 200px;"></div>
          <h2>신청해 주셔서 감사드립니다.</h2>
          <div style="height: 30px;"></div>
          <p>곧 변호사님께서 연락을 드릴 예정입니다.<br><br>
            밝은 내일의 시작,<br>
            LawPlus와 함께하세요.
          </p>
          <div style="height: 30px;"></div>
          <button v-on:click="goToHomeView">홈 화면으로 돌아가기</button>
          <div style="height: 200px;"></div>
          
    
            <!-- <div class="background-nr" id="background-nr"></div>
            <div style="height: 140px;"></div>
            <h2>LawPlus 신청 목록 보기</h2>
            <div style="height: 50px;"></div>
            <h4>수임 가능한 사건은 진한 글씨로 표시됩니다.</h4>
            <h4>상세 정보는 열람할 수 없습니다.</h4> -->

            <!-- 내 신청정보 -->
            <!-- <div v-for = "i in cases" :key="i"> -->
              <!-- 수임가능할때 -->
              <!-- <div class="client-container" v-if="i.isAccepted == false">
                <p><span style="font-family: Pretendard-Bold;">내 정보</span></p>
                <p>{{ i.name[0] }}**</p>
                <p>{{ i.age }}</p>
                <p>{{ i.jobNow }}</p>
                <p>{{ i.debt }}</p>
              </div> -->
              <!-- 수임불가능할때 -->
              <!-- <div class="client-container" v-if="i.isAccepted == true">
                  <p><span style="font-family: Pretendard-Bold; color: #BBBBBB ">내 정보</span></p>
                  <p><span style="color: #BBBBBB">{{ i.name[0] }}**</span></p>
                  <p><span style="color: #BBBBBB">{{ i.age }}</span></p>
                  <p><span style="color: #BBBBBB">{{ i.jobNow }}</span></p>
                  <p><span style="color: #BBBBBB">{{ i.debt }}</span></p>
              </div> -->
          <!-- </div> -->
        </main>
    
        <footer>
            <div style="height: 50px;"></div>
            <p><span style="color: #FFFFFF;">
                대표이사   |   김민석<br>
                로반 주식회사   |    729-86-01007<br>
                주소   |   서울특별시 서초구 강남대로 107길 21, 2층<br>
                대표번호   |   010-8081-2132<br>
                E-mail   |   tellmedia@naver.com
            </span></p>
            <div style="height: 50px;"></div>
        </footer>
    </div>
  </template>

<script>
// import { db } from '../firebase/index.js'
// import { collection, getDocs } from 'firebase/firestore'
export default {
  name: 'case-list',
  // created() {
  //   this.fetchCases()
  // },
  data() {
    return {
      cases: []
    }
  },
  methods: {
		// async fetchCases() {
    //   const querySnapshot = await getDocs(collection(db, "cases"))
    //   querySnapshot.forEach((doc) => {
    //     this.cases.push(doc.data())
    //   })
    // },
    goToHomeView () {
      this.$router.push('/')
     },
  },
 
  components: {
    
  }
}
</script>

  